// useAuth.jsx

import { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { supabase } from '../../data/supabase';
import { setAvatar } from '../../store/reducers/avatar';

const AuthContext = createContext({
  session: {},
  currentUser: {},
  patient: [],
  specialist: [],
  signOut: () => {},
});

export function AuthProvider({ children }) {
  const [session, setSession] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [patient, setPatient] = useState([]);
  const [admin, setAdmin] = useState([]);
  const [specialist, setSpecialist] = useState([]);
  const [specialistList,setSpecialistList] = useState([])

  const dispatch = useDispatch();

  // Função para carregar os dados do usuário
  const fetchUserData = async () => {
    try {
      console.log('Buscando usuário...');
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) {
        console.error("Erro ao buscar o usuário:", userError);
        return;
      }

      setCurrentUser(user);

      // Recuperando a imagem de avatar associada ao usuário
      const { data } = await supabase.storage
        .from('uploads')
        .list(`${user?.id}/avatar/`, { limit: 1 });

      if (data && data.length > 0) {
        const avatarUrl = `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/uploads/${user?.id}/avatar/${data[0]?.name}`;
        dispatch(setAvatar(avatarUrl)); // Atualiza o Redux com o avatar
      }

      console.log('Buscando sessão...');
      const { data: sessionData, error: sessionError } = await supabase.auth.getSession();
      if (sessionError) {
        console.error("Erro ao buscar a sessão:", sessionError);
        return;
      }
      setSession(sessionData?.session || {});

      console.log('Buscando paciente...');
      const { data: patientData, error: patientError } = await supabase
        .from('patient')
        .select()
        .eq('id_user', user?.id);
      if (patientError) {
        console.error("Erro ao buscar paciente:", patientError);
      }

      console.log('patientData', patientData);
      
      setPatient(patientData || []);

      // Agora, buscamos o avatar do paciente
    if (patientData && patientData[0]) {
      const { data: avatarData } = await supabase.storage
        .from('uploads')
        .list(`${user.id}/avatar/`, { limit: 1, sortBy: { column: 'updated_at', order: 'desc' } });

      if (avatarData && avatarData.length > 0) {
        // Armazena o nome ou o caminho do avatar
        setPatient(prevPatient => {
          const updatedPatient = [...prevPatient];
          updatedPatient[0] = { ...updatedPatient[0], avatar: avatarData[0].name }; // Assume que o primeiro paciente é o que você quer
          return updatedPatient;
        });
      }
    }

      console.log('Buscando especialista...');
      const { data: specialistData, error: specialistError } = await supabase
        .from('specialist')
        .select()
        .eq('id_user', user?.id);
      if (specialistError) {
        console.error("Erro ao buscar especialista:", specialistError);
      }
      setSpecialist(specialistData || []);

      if (specialistData && specialistData[0]) {
        const { data: avatarData } = await supabase.storage
          .from('uploads')
          .list(`${user.id}/avatar/`, { limit: 1, sortBy: { column: 'updated_at', order: 'desc' } });
  
        if (avatarData && avatarData.length > 0) {
          // Armazena o nome ou o caminho do avatar
          setSpecialist(prevSpecialist => {
            const updatedSpecialist = [...prevSpecialist];
            updatedSpecialist[0] = { ...updatedSpecialist[0], avatar: avatarData[0].name }; // Assume que o primeiro paciente é o que você quer
            return updatedSpecialist;
          });
        }
      }

      const { data: specialists, error: specialistErro} = await supabase
      .from('specialist')
      .select('*');
      if(specialistErro){ 
        console.log('Erro no especialista');        
      }
      setSpecialistList(specialists || [])
    

      console.log('Buscando admins...');
      const { data: adminData, error: adminError } = await supabase
        .from('admin')
        .select()
        .eq('id_user', user?.id);
      if (adminError) {
        console.error("Erro ao buscar usuario admin:", adminError);
      }
      setAdmin(adminData || []);

    } catch (error) {
      console.error("Erro ao carregar os dados do usuário:", error);
    } finally {
    document.body.classList.remove('loading-indicator');

      setIsLoading(false); // Garanta que o estado de loading seja atualizado no final
    }
  };

  useEffect(() => {
    const loadData = async () => {
      console.log('Iniciando o carregamento de dados...');
      await fetchUserData(); // Aguarda o carregamento completo
    };

    loadData();
  }, []); // A dependência vazia significa que o código será executado apenas uma vez

  if (isLoading) {
    document.body.classList.add('loading-indicator');
  } else {
    document.body.classList.remove('loading-indicator');
    
  }
  
  return (
    <AuthContext.Provider value={{
      session,
      currentUser,
      patient,
      setPatient,
      specialist,
      setSpecialist,
      specialistList,
      admin,
      fetchUserData,
      signOut: () => supabase.auth.signOut(),
    }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used inside of a AuthProvider");
  }
  return context;
};
