import { useEffect, useState } from "react";
import { supabase } from "../../../data/supabase";

function ReviewSpecialistModal({ extraObject }) {
  const { specialist } = extraObject; // Dados do especialista selecionado
  const [reviews, setReviews] = useState([]); // Inicialize como um array
  const [loading, setLoading] = useState(true); // Indicador de carregamento

  // Função para carregar as avaliações
  async function loadReview() {
    setLoading(true); // Ative o indicador de carregamento
    const { data, error } = await supabase
      .from("reviews")
      .select("*")
      .eq("id_spec", specialist?.id);

    if (data) {
      setReviews(data); // Atualize o estado com os dados recebidos
    } else {
      console.error(error);
    }
    setLoading(false); // Desative o indicador de carregamento
  }

  // Carregue os reviews ao montar o componente
  useEffect(() => {
    loadReview();
  }, []);

  return (
    <div className="max-w-3xl mx-auto bg-white rounded-lg p-6 mt-6">
      {/* Cabeçalho com informações do especialista */}
      

      {/* Lista de avaliações */}
      <div className="mt-6">
        <h3 className="text-lg font-bold text-gray-800">Avaliações</h3>
        <div className="mt-4 space-y-4">
          {loading ? (
            // Indicador de carregamento enquanto os dados são buscados
            <p className="text-gray-500">Carregando avaliações...</p>
          ) : reviews.length > 0 ? (
            reviews.map((review, index) => (
              <div
                key={index}
                className="bg-gray-50 border border-gray-200 rounded-lg shadow-md p-4"
              >
                <p className="text-gray-800 font-semibold">{review.comment}</p>
                <div className="flex items-center justify-between mt-2">
                  <div>
                    <p className="text-gray-600 text-sm">{review.name}</p>
                    <p className="text-gray-400 text-xs">{review.headline}</p>
                  </div>
                  <div className="flex items-center">
                    {[...Array(5)].map((_, i) => (
                      <svg
                        key={i}
                        xmlns="http://www.w3.org/2000/svg"
                        fill={i < review.rating ? "orange" : "gray"}
                        className="w-5 h-5"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 2l2.65 7.98H22l-6.5 4.72L16.93 22 12 17.76 7.07 22l1.43-7.3L2 9.98h7.35z" />
                      </svg>
                    ))}
                  </div>
                </div>
              </div>
            ))
          ) : (
            // Mensagem caso não haja avaliações
            <p className="text-gray-500">
              Ainda não há avaliações para este especialista.
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default ReviewSpecialistModal;