import { useEffect, useState } from "react";
import { supabase } from "../../../../../data/supabase";

function Image({ userId }) {
  const [actualSrc, setActualSrc] = useState();
  useEffect(() => {
    const imageProFun = async () => {
      const { data } = await supabase.storage
        .from('uploads')
        .list(`${userId}/avatar`, {
          offset: 0,
          sortBy: {
            column: 'created_at',
            order: 'desc',
          },
        });

      if (data.length > 0) {
        const result = `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/uploads/${userId}/avatar/${data[0]?.name}`;
        if(data[0]?.name !== undefined ) {

          setActualSrc(result)
        }
      }
      return null;
    };

    imageProFun();
  }, [userId]);

  return (
    <img
      src={actualSrc ?? 'https://cdn-icons-png.flaticon.com/512/6596/6596121.png'}
      className="inline-block h-20 w-20 rounded-full"
      alt=""
    />
  );
}

export default Image;