import { useEffect, useState } from 'react';
import { supabase } from '../../../../data/supabase';
import { useAuth } from '../../../../shared/context/useAuth';
import CardSpecialist from '../../components/CardSpecialist/index';

function Favorite() {
  const { patient } = useAuth();
  const [favoritesPatient, setFavoritesPatient] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchFavorites = async () => {
      if (patient?.[0]?.id) {
        try {
          // 1. Buscar todos os registros de favoritos para o paciente
          const { data: patientFavorites, error: patientError } = await supabase
            .from('patient_favorite')
            .select('list_favorite')
            .eq('id_patient', patient[0]?.id);

          if (patientError) {
            console.error('Erro ao carregar favoritos do paciente:', patientError);
            return;
          }

          // 2. Concatenar todos os favoritos (caso existam vários registros)
          let allFavoriteIds = [];

          // Itera sobre os registros e concatena os IDs dos especialistas favoritos
          patientFavorites?.forEach(favRecord => {
            console.log('favRev', favRecord);
            
            if (favRecord?.list_favorite) {
              const ids = favRecord.list_favorite
                .filter(favorite => favorite.isFavorite) // Filtra apenas os favoritos
                .map(favorite => favorite.id); // Mapeia para os IDs dos especialistas
              allFavoriteIds = [...allFavoriteIds, ...ids]; // Adiciona ao array de favoritos
            }
          });

          console.log('allFavoriteIds', allFavoriteIds);
          

          // 3. Se houver IDs de especialistas, buscar os dados completos dos especialistas
          if (allFavoriteIds.length > 0) {
            const { data: specialistsData, error } = await supabase
              .from('specialist')
              .select('*')
              .in('id_user', allFavoriteIds); // Passa o array de IDs de especialistas

            if (error) {
              console.error('Erro ao carregar dados dos especialistas:', error);
              return;
            }

            // 4. Atualiza o estado com os dados completos dos especialistas
            console.log('specialistsData', specialistsData);
            
            setFavoritesPatient(specialistsData || []);
          } else {
            // Se não houver favoritos, define como array vazio
            setFavoritesPatient([]);
          }
        } catch (error) {
          console.error('Erro ao carregar favoritos:', error);
        } finally {
          setLoading(false); // Garantir que o loading seja desativado após a tentativa de carregar
        }
      }
    };

    fetchFavorites();
  }, [patient]); // Reexecuta o useEffect quando o paciente mudar

  const updateFavorites = (updatedFavorites) => {
    setFavoritesPatient(updatedFavorites);
  };

  
  // eslint-disable-next-line no-nested-ternary
  return (
    <div className="w-full">
      {favoritesPatient?.length > 0 ? (
        <CardSpecialist
          specialistData={favoritesPatient}
          setFavoritesPatient={updateFavorites}
        />
      ) : (
        <div className="col-span-12 flex flex-grow items-center justify-center">
          <div className="rounded-lg bg-white p-8 text-center shadow-xl">
            <h1 className="mb-4 text-4xl font-bold">Nenhum favorito!</h1>
            <p className="text-gray-600">
              Adicione seus especialistas favoritos aqui.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Favorite;
