import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../../store/reducers/modal';
import { MODAL_BODY_TYPES } from '../../../utils/globalConstantUtil';
import AppointmentDetailsModalBody from '../../components/Modal/AppointmentDetailsModalBody';
import ConfirmationModalBody from '../../components/Modal/ConfirmationModalBody';
import VacationModalBody from '../../components/Modal/VacationModalBody';
import ModalSpecialist from '../Modal/ModalSpecialist';
import ModalSuccessSingup from '../Modal/ModalSuccessSingup';
import ReviewModal from '../Modal/ReviewModal';
import ReviewSpecialistModal from '../Modal/ReviewSpecialistModal';

function ModalLayout() {
  const { isOpen, title, size, extraObject, bodyType } = useSelector(
    (state) => {
      return state.modal;
    },
  );

  const dispatch = useDispatch();

  const close = (e) => {
    dispatch(closeModal(e));
  };

  return (
    <>
      {/* The button to open modal */}

      {/* Put this part before </body> tag */}
      <div className={`modal ${isOpen ? 'modal-open' : ''}`}>
        <div className={`modal-box  ${size === 'lg' ? 'max-w-5xl' : ''}`}>
          <button
            className="btn btn-sm btn-circle absolute right-2 top-2"
            onClick={() => close()}
          >
            ✕
          </button>
          <h3 className="font-semibold text-2xl text-center">{title}</h3>
          <div className="divider" />

          {/* Loading modal body according to different modal type */}
          {
            {
              [MODAL_BODY_TYPES.SCHEDULE]: <p>TESTE</p>,
              [MODAL_BODY_TYPES.SUCCESS_SIGNUP]: (
                <ModalSuccessSingup
                  closeModal={close}
                  extraObject={extraObject}
                />
              ),
              [MODAL_BODY_TYPES.CONFIRMATION]: (
                <ConfirmationModalBody
                  extraObject={extraObject}
                  closeModal={close}
                />
              ),
              [MODAL_BODY_TYPES.REVIEW]: (
                <ReviewModal
                  extraObject={extraObject}
                  closeModal={close}
                />
              ),
              [MODAL_BODY_TYPES.REVIEW_SPEC]: (
                <ReviewSpecialistModal
                  extraObject={extraObject}
                  closeModal={close}
                />
              ),
              [MODAL_BODY_TYPES.SPECIALIST]: (
                <ModalSpecialist extraObject={extraObject} closeModal={close} />
              ),
              [MODAL_BODY_TYPES.CONFIRMATION]: (
                <ConfirmationModalBody
                  extraObject={extraObject}
                  closeModal={close}
                />
              ),
              [MODAL_BODY_TYPES.VACATION]: (
                <VacationModalBody
                  extraObject={extraObject}
                  closeModal={close}
                />
              ),
              [MODAL_BODY_TYPES.APPOINTMENT_DETAILS]: (
                <AppointmentDetailsModalBody
                  extraObject={extraObject}
                  closeModal={close}
                />
              ),
              // eslint-disable-next-line react/self-closing-comp
              [MODAL_BODY_TYPES.DEFAULT]: <div></div>,
            }[bodyType]
          }
        </div>
      </div>
    </>
  );
}

export default ModalLayout;
